<template>
  <div style="width: 100%">

    <ws-navigation-header
        v-model="navigation"
        :items="items"
        :no-number="noNumber"
        :padding="padding"
        v-if="!hideHeader"
    />

    <slot name="prepend"></slot>

    <div v-for="(item,i) in items" :key="i" >

      <div v-if="navigation === item.value"
           :style="`padding-left : ${padding};padding-right : ${padding}`"
           :class="!noVerticalPadding ? 'py-5' : ''"
      >
        <slot :name="`item.${item.value}`" ></slot>
      </div>

    </div>



  </div>
</template>

<script>
export default {
  name: "wsNavigation",
  props : {
    value : {
      type : String
    },
    items : {
      type : Array ,
      default() { return {} }
    },
    padding : {
      type : String
    },
    noVerticalPadding : {
      type : Boolean,
      default : false
    },
    noNumber : {
      type : Boolean,
      default : false
    },
    hideHeader : {
      type : Boolean,
      default : false
    }
  },
  watch : {
    value() {
      if ( this.value !== this.navigation ) {
        this.navigation = this.value
      }
    },
    navigation() {
      if ( this.value !== this.navigation ) {
        this.$emit('input' ,this.navigation)
      }
    }
  },
  data() {
    return {
      navigation : ''
    }
  },
  mounted() {
    this.navigation = this.value
    if ( !this.navigation && this.items.length > 1  ) {
      this.navigation = this.items[0].value
    }
  }
}
</script>

<style scoped>

</style>